import React, { useState } from "react"
import { Button } from "reactstrap"
import { toast } from "react-toastify"
import { Popover, Typography, makeStyles } from "@material-ui/core"
import { css } from "glamor"

const PopoverButton = props => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { className, label, confirmMessage, onConfirm, afterConfirm, finalConfirm, okText } = props

  const useStyles = makeStyles(theme => ({
    typography: {
      padding: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  const handleConfirm = () => {
    setAnchorEl(null)
    onConfirm()
      .then(response => {
        if (response && response.status) {
          const {
            status: { description },
          } = response
          setAnchorEl(null)
          if (afterConfirm) afterConfirm()
          if (finalConfirm) finalConfirm()
          toast(`${description}`, {
            type: "success",
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }
      })
      .catch(error => {
        if (finalConfirm) finalConfirm()
        toast(`${error}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const handleCancel = () => {
    setAnchorEl(null)
  }

  const handleClick = e => {
    setAnchorEl(e.currentTarget)
  }

  return (
    <>
      <Button color="danger" onClick={handleClick} className={className}>
        {label}
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleCancel}
      >
        <div>
          <Typography className={classes.typography}>{confirmMessage}</Typography>
          <div {...css(styles.popoverBtn)}>
            {" "}
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit" className="mr-2" color={"primary"} onClick={handleConfirm}>
              {okText || "OK"}
            </Button>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default PopoverButton

const styles = {
  popoverBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px",
    marginRight: "10px",
  },
}
